const state = () => ({
  data: [],
  searchString: '',
  userInteract: false,
  isLoading: false,
})

const actions = {
  /* Loading */
  initLoading({ commit }) {
    commit('SET_INIT_LOADING', true)
  },

  endLoading({ commit }) {
    commit('SET_INIT_LOADING', false)
  },

  /* Colection */
  setData({ commit }, data) {
    commit('SET_DATA', data)
  },

  clearColectionState({ commit }) {
    commit('SET_DATA', [])
    commit('SET_SEARCH_STRING', '')
    commit('SET_INIT_LOADING', false)
  },

  /* Search String  */
  clearSearchString({ commit }) {
    commit('SET_SEARCH_STRING', '')
    commit('REGISTER_INTERACT')
  },

  doSearchByString({ commit, dispatch }, searchString) {
    dispatch('paginator/changeCurrentPage', 1, { root: true })
    commit('SET_SEARCH_STRING', searchString)
    commit('REGISTER_INTERACT')
  },

  /* Paginator */
  changeCurrentPage({ commit, dispatch }, pageNumber) {
    dispatch('paginator/changeCurrentPage', pageNumber, { root: true })
    commit('REGISTER_INTERACT')
  },
}

const mutations = {
  SET_INIT_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },

  SET_DATA(state, data) {
    state.data = []
    state.data = data
  },

  SET_SEARCH_STRING(state, searchString) {
    state.searchString = searchString
  },

  REGISTER_INTERACT(state) {
    state.userInteract = !state.userInteract
  },
}

const getters = {
  getIsLoading: (state) => {
    return state.isLoading
  },
  getData: (state) => {
    return state.data
  },
  getDataCount: (state) => {
    return state.data.length
  },
  getSearchString: (state) => {
    return state.searchString
  },
  getUserInteract: (state) => {
    return state.userInteract
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
